// загальні класи
@use "mixins/breakpoints" as mix;

.icon {
  display: flex;
  border-radius: 12px;
  background-color: var(--clr-bcg-light);
  padding: 9px;

  @include mix.breakpoint("lg") {
    padding: 14px;
  }
}
