/*
* Variables for breakpoints
*/
$mobile: 320px;
$tablet: 768px;
$desktop: 992px;

/*
* Map for breakpoints
*/
$breakpoints: (
  "sm": "screen and (min-width: #{$mobile})",
  "md": "screen and (min-width: #{$tablet})",
  "lg": "screen and (min-width: #{$desktop})",
);

@mixin breakpoint($size) {
  $query: map-get($breakpoints, $size);

  @media #{$query} {
    @content;
  }
}
