/* You can add global styles to this file, and also import other style files */
@use "mixins/breakpoints" as mix;
@import "variables/variables";
@import "variables/colors";
@import "overrides/themes";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "overrides/primeng";
@import "primeicons/primeicons.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: var(--clr-text-primary);
}

input,
button,
select {
  font: inherit;
}

h1,
h2,
h3,
p {
  margin: 0;
}

button,
a {
  &:hover {
    cursor: pointer;
  }
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.container {
  max-width: 1440px;
  margin-inline: auto;
  padding: 0 16px;

  @include mix.breakpoint("lg") {
    padding: 0 24px;
  }
}

body.modalOpen {
  @media (max-width:990px) {
    height: 100vh;
    overflow-y: hidden;
  }
}

.fw-bold {
  font-weight: 900;
}
