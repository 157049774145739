:root {
  --clr-accent: #09639f;
  --clr-white: #ffffff;
  --clr-black: #000000;
  --clr-bcg-light: #eaeaee;
  --clr-text-primary: #252525;
  --clr-text-secondary: #6f6f6f;
  --clr-error: #cc0000;
  --clr-midnight-grey:#BCC1C5;
}
