.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border: none;
}

.p-datepicker .p-datepicker-header {
  border-bottom: none !important;
}

.p-datepicker {
  border: none;
}

.p-datepicker-multiple-month .p-datepicker-group-container {
  gap: 30px;
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker table td > span.p-highlight {
  border-radius: 0;
  background-color: #d3d3d340;
  color: #252525;
}

.p-datepicker table td > span.p-highlight.active {
  background-color: var(--clr-accent);
  color: var(--clr-white);
  border-radius: 50%;
}

td:has(span.p-highlight.first) {
  background-color: #d3d3d340;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

td:has(span.p-highlight.last) {
  background-color: #d3d3d340;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.p-inputswitch-slider {
  background-color: var(--clr-bcg-light);
  outline: 1px solid var(--clr-midnight-grey);

  &::before {
    height: 100%;
    width: min-content;
    aspect-ratio: 1;
    margin-top: 0;
    top: 0;
    left: 0;
    background-color: var(--clr-midnight-grey);
    transition: all ease-in-out 300ms;
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--clr-accent);
  outline: none;

  &::before {
    background-color: var(--clr-white);
  }
}

.p-disabled .p-inputswitch-slider {
  outline: none;

  &::before {
    background-color: var(--clr-white);
  }
}

.p-tooltip .p-tooltip-text {
  font-size: 14px;
  line-height: 2;
  background-color: var(--clr-accent);
}

